import {
  Button,
  Group,
  Navbar,
  Portal,
  Radio,
  Stepper,
  TextInput,
  Checkbox,
  Box,
  Flex,
  Text,
  Title,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useMediaQuery } from '@mantine/hooks';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(faChevronLeft, faChevronRight);
function NavbarStepper(props: any) {
  const [active, setActive] = useState(0);
  const [contactCheck, setContactCheck] = useState('');
  const [callCheck, setCallCheck] = useState('');
  const [checked, setChecked] = useState(false);
  const [callChecked, setCallChecked] = useState(false);
  const [mfaRadio, setMfaRadio] = useState('');
  const [cyberSecRadio, setCyberSecRadio] = useState('');
  const [routerPasswordRadio, setRouterPasswordRadio] = useState('');
  const [dataControlRadio, setDataControlRadio] = useState('');
  const [adminPermsRadio, setAdminPermsRadio] = useState('');
  const [sharedLoginsRadio, setSharedLoginsRadio] = useState('');
  const [antivirusRadio, setAntivirusRadio] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [highestStepVisited, setHighestStepVisited] = useState(active);
  let navigate = useNavigate();
  const formSubmission = (values: {
    email: string;
    name: string;
    companyName: string;
    contactCheck: string;
    callCheck: string;
    mfa: string;
    cyberSecTraining: string;
    routerPassword: string;
    dataControl: string;
    adminPerms: string;
    sharedLogins: string;
    antivirus: string;
  }) => {
    let array = Object.entries(values);
    let postObject: { [x: string]: any };
    postObject = {};
    array.map((value, index) => {
      if (value[1] === '1') {
        postObject[value[0]] = true;
      } else if (value[1] === '0') {
        postObject[value[0]] = false;
      } else if (value[1] === '2') {
        postObject[value[0]] = false;
      } else {
        postObject[value[0]] = value[1];
      }
    });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ postObject }),
    };
    fetch(
      'https://prod-62.westeurope.logic.azure.com:443/workflows/a7e536c882fd457b973c790e39961cf3/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=dXntsGO_ZXs5GnVSkEBWnJSlXBCaLk80Le-qWnhrIo8',
      requestOptions
    ).then((response) => response.json());
    setDisabled(true);
    props.setValues(values);
    nextStep();
    navigate('/complete');

    //  .then(values => console.log(values));
  };

  const nextStep = () => {
    setActive((current) => {
      if (!form.isValid()) {
        form.validate();
        return current;
      } else {
        setHighestStepVisited((hSC) => Math.max(hSC, current + 1));
        return current < 8 ? current + 1 : current;
      }
    });
  };

  const shouldAllowSelectStep = (step: number) =>
    highestStepVisited >= step && active !== step;

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: '',
      name: '',
      companyName: '',
      contactCheck: '',
      callCheck: '',
      mfa: '',
      cyberSecTraining: '',
      routerPassword: '',
      dataControl: '',
      adminPerms: '',
      sharedLogins: '',
      antivirus: '',
    },
    validate: (values) => {
      if (active === 0) {
        return {
          email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
            values.email
          )
            ? null
            : 'Enter a valid email',
          name: values.name === '' ? 'Enter Name' : null,
          companyName: values.companyName === '' ? 'Enter Company Name' : null,
        };
      }
      if (active === 1) {
        return {
          mfa: values.mfa === '' ? 'Pick Option' : null,
        };
      }
      if (active === 2) {
        return {
          cyberSecTraining:
            values.cyberSecTraining === '' ? 'Pick Option' : null,
        };
      }
      if (active === 3) {
        return {
          routerPassword: values.routerPassword === '' ? 'Pick Option' : null,
        };
      }
      if (active === 4) {
        return {
          dataControl: values.dataControl === '' ? 'Pick Option' : null,
        };
      }
      if (active === 5) {
        return {
          adminPerms: values.adminPerms === '' ? 'Pick Option' : null,
        };
      }
      if (active === 6) {
        return {
          sharedLogins: values.sharedLogins === '' ? 'Pick Option' : null,
        };
      }
      if (active === 7) {
        return {
          antivirus: values.antivirus === '' ? 'Pick Option' : null,
        };
      }
      return {};
    },
  });
  const largeScreen = useMediaQuery('(min-width: 60em)');

  useEffect(() => {
    if (checked === true) {
      setContactCheck('1');
    } else {
      setContactCheck('0');
    }
  }, [checked]);
  useEffect(() => {
    if (callChecked === true) {
      setCallCheck('1');
    } else {
      setCallCheck('0');
    }
  }, [callChecked]);
  useEffect(() => {
    form.setFieldValue('contactCheck', contactCheck);
  }, [contactCheck]);
  useEffect(() => {
    form.setFieldValue('callCheck', callCheck);
  }, [callCheck]);
  return (
    <form
      id='cyberform'
      onSubmit={form.onSubmit((values) => formSubmission(values))}
    >
      <Navbar
        bg='pink.0'
        style={{ width: 'fit-content', overflow: 'scroll' }}
        p='xl'
      >
        <Stepper
          active={active}
          orientation='vertical'
          onStepClick={(value) => {
            if (!disabled) {
              setActive(value);
            }
          }}
          breakpoint='sm'
          iconPosition='left'
          // allowNextStepsSelect={false}
        >
          <Stepper.Step
            label={largeScreen ? 'Contact Info' : ''}
            description={largeScreen ? 'About you' : ''}
            allowStepSelect={shouldAllowSelectStep(0)}
          >
            <Portal target='#step'>
              <Flex align={'center'} direction={'column'}>
                <h5>
                  This quick Cyber Health Check will give you an indication of
                  how secure you and your business are from Online risks.
                </h5>
                <TextInput
                  size='lg'
                  mt='xs'
                  w={largeScreen ? '60%' : '100%'}
                  pb={20}
                  errorProps={{ color: 'pink.0' }}
                  ta='left'
                  withAsterisk
                  label='Name'
                  {...form.getInputProps('name')}
                  onChange={(event) =>
                    form.setFieldValue('name', event.currentTarget.value)
                  }
                />
                <TextInput
                  size='lg'
                  mt='xs'
                  w={largeScreen ? '60%' : '100%'}
                  pb={20}
                  errorProps={{ color: 'pink.0' }}
                  ta='left'
                  withAsterisk
                  label='Company Name'
                  {...form.getInputProps('companyName')}
                  onChange={(event) =>
                    form.setFieldValue('companyName', event.currentTarget.value)
                  }
                />
                <TextInput
                  mt='xs'
                  size='lg'
                  w={largeScreen ? '60%' : '100%'}
                  errorProps={{ color: 'pink.0' }}
                  pb={20}
                  ta='left'
                  type={'email'}
                  withAsterisk
                  label='Email'
                  {...form.getInputProps('email')}
                  onChange={(event) =>
                    form.setFieldValue('email', event.currentTarget.value)
                  }
                />

                <Checkbox
                  size='lg'
                  mt='xs'
                  // checked={false}
                  name='contactCheck'
                  label="I'm happy to be contacted by BC Technologies"
                  color='violet'
                  checked={checked}
                  value={contactCheck}
                  onChange={(event) => {
                    setChecked(!checked);
                  }}
                />
                <Checkbox
                  size='lg'
                  mt='xs'
                  // checked={false}
                  name='callCheck'
                  label="I'd like to arrange a call with BC Technologies"
                  color='violet'
                  checked={callChecked}
                  value={callCheck}
                  onChange={(event) => {
                    setCallChecked(!callChecked);
                  }}
                />
              </Flex>
            </Portal>
          </Stepper.Step>
          <Stepper.Step
            label={largeScreen ? 'Question 1' : ''}
            description={largeScreen ? 'Multi-Factor Authentication' : ''}
            allowStepSelect={shouldAllowSelectStep(1)}
          >
            <Portal target='#step'>
              <Box mb={'lg'}>
                <Title order={largeScreen ? 1 : 3}>
                  Do you have Multi-Factor Authentication (MFA) enabled across
                  all platforms?
                </Title>
              </Box>

              <Radio.Group
                size='xl'
                name='mfaRadio'
                {...form.getInputProps('mfa')}
                label={
                  <Text c='dimmed' fz='md' mb={'lg'}>
                    MFA is when you require a further step of approval in
                    addition to username and password to access your platform.
                  </Text>
                }
                errorProps={{ mt: 'lg', color: 'pink.0' }}
                value={mfaRadio}
                onChange={(value) => {
                  form.setFieldValue('mfa', value);
                  setMfaRadio(value);
                }}
                inputWrapperOrder={['label', 'input', 'error']}
                inputContainer={(children) => (
                  <Flex gap='xl' align='center' justify='center'>
                    {children}{' '}
                  </Flex>
                )}
              >
                <Group spacing='xl'>
                  <Radio value={'1'} label='Yes' />
                  <Radio value={'0'} label='No' />
                </Group>
              </Radio.Group>
            </Portal>
          </Stepper.Step>
          <Stepper.Step
            label={largeScreen ? 'Question 2' : ''}
            description={largeScreen ? 'Cyber Security Training' : ''}
            allowStepSelect={shouldAllowSelectStep(2)}
          >
            <Portal target='#step'>
              <Box mb={'lg'}>
                <Title order={largeScreen ? 1 : 3}>
                  Do your staff undergo regular cyber security training?
                </Title>
              </Box>
              <Radio.Group
                size='xl'
                name='cyberSecRadio'
                label={
                  <Text c='dimmed' fz='md' mb={'lg'}>
                    This includes ensuring they are aware of cyber security
                    polices and informing them of any change
                  </Text>
                }
                errorProps={{ mt: 'lg', color: 'pink.0' }}
                {...form.getInputProps('cyberSecTraining')}
                value={cyberSecRadio}
                onChange={(value) => {
                  form.setFieldValue('cyberSecTraining', value);
                  setCyberSecRadio(value);
                }}
                inputWrapperOrder={['label', 'input', 'error']}
                inputContainer={(children) => (
                  <Flex gap='xl' align='center' justify='center'>
                    {children}{' '}
                  </Flex>
                )}
              >
                <Group spacing='xl'>
                  <Radio value={'1'} label='Yes' />
                  <Radio value={'0'} label='No' />
                </Group>
              </Radio.Group>
            </Portal>
          </Stepper.Step>

          <Stepper.Step
            label={largeScreen ? 'Question 3' : ''}
            description={largeScreen ? 'Internet Router Password' : ''}
            allowStepSelect={shouldAllowSelectStep(3)}
          >
            <Portal target='#step'>
              <Box mb={'lg'}>
                <Title order={largeScreen ? 1 : 3}>
                  Do you change the default password on your internet router
                  when you first recieve it?
                </Title>
              </Box>
              <Radio.Group
                size='xl'
                name='routerPasswordRadio'
                label={
                  <Text c='dimmed' fz='md' mb={'lg'}>
                    This is done on the router settings which can be accessed in
                    your browser
                  </Text>
                }
                errorProps={{ mt: 'lg', color: 'pink.0' }}
                {...form.getInputProps('routerPassword')}
                value={routerPasswordRadio}
                onChange={(value) => {
                  form.setFieldValue('routerPassword', value);
                  setRouterPasswordRadio(value);
                }}
                inputWrapperOrder={['label', 'input', 'error']}
                inputContainer={(children) => (
                  <Flex gap='xl' align='center' justify='center'>
                    {children}{' '}
                  </Flex>
                )}
              >
                <Group spacing='xl'>
                  <Radio value={'1'} label='Yes' />
                  <Radio value={'0'} label='No' />
                </Group>
              </Radio.Group>
            </Portal>
          </Stepper.Step>

          <Stepper.Step
            label={largeScreen ? 'Question 4' : ''}
            description={largeScreen ? 'Data Control' : ''}
            allowStepSelect={shouldAllowSelectStep(4)}
          >
            <Portal target='#step'>
              <Box mb={'lg'}>
                <Title order={largeScreen ? 1 : 3}>
                  Do you control what staff have access to certain data?
                </Title>
              </Box>
              <Radio.Group
                size='xl'
                name='dataControlRadio'
                label={
                  <Text c='dimmed' fz='md' mb={'lg'}>
                    Having read access set on a group or user basis for folders
                    or storing data in separate locations for different staff
                    e.g. locally
                  </Text>
                }
                errorProps={{ mt: 'lg', color: 'pink.0' }}
                {...form.getInputProps('dataControl')}
                value={dataControlRadio}
                onChange={(value) => {
                  form.setFieldValue('dataControl', value);
                  setDataControlRadio(value);
                }}
                inputWrapperOrder={['label', 'input', 'error']}
                inputContainer={(children) => (
                  <Flex gap='xl' align='center' justify='center'>
                    {children}{' '}
                  </Flex>
                )}
              >
                <Group spacing='xl'>
                  <Radio value={'1'} label='Yes' />
                  <Radio value={'0'} label='No' />
                  <Radio value={'2'} label='Do not know' />
                </Group>
              </Radio.Group>
            </Portal>
          </Stepper.Step>

          <Stepper.Step
            label={largeScreen ? 'Question 5' : ''}
            description={largeScreen ? 'Admin Privileges' : ''}
            allowStepSelect={shouldAllowSelectStep(5)}
          >
            <Portal target='#step'>
              <Box mb={'lg'}>
                <Title order={largeScreen ? 1 : 3}>
                  Do your staff have administrator privileges to install
                  software on device?
                </Title>
              </Box>
              <Radio.Group
                size='xl'
                name='adminPermsRadio'
                label={
                  <Text c='dimmed' fz='md' mb={'lg'}>
                    When installing a program there will be a windows popup that
                    requires an admin account to enter a password to install the
                    program
                  </Text>
                }
                errorProps={{ mt: 'lg', color: 'pink.0' }}
                {...form.getInputProps('adminPerms')}
                value={adminPermsRadio}
                onChange={(value) => {
                  form.setFieldValue('adminPerms', value);
                  setAdminPermsRadio(value);
                }}
                inputWrapperOrder={['label', 'input', 'error']}
                inputContainer={(children) => (
                  <Flex gap='xl' align='center' justify='center'>
                    {children}{' '}
                  </Flex>
                )}
              >
                <Group spacing='xl'>
                  <Radio value={'0'} label='Yes' />
                  <Radio value={'1'} label='No' />
                  <Radio value={'2'} label='Do not know' />
                </Group>
              </Radio.Group>
            </Portal>
          </Stepper.Step>

          <Stepper.Step
            label={largeScreen ? 'Question 6' : ''}
            description={largeScreen ? 'Shared Logins' : ''}
            allowStepSelect={shouldAllowSelectStep(6)}
          >
            <Portal target='#step'>
              <Box mb={'lg'}>
                <Title order={largeScreen ? 1 : 3}>
                  Do staff share PC logins?
                </Title>
              </Box>
              <Radio.Group
                size='xl'
                name='sharedLoginsRadio'
                label={
                  <Text c='dimmed' fz='md' mb={'lg'}>
                    Staff using the same computer without having multiple user
                    accounts set up on the computer
                  </Text>
                }
                errorProps={{ mt: 'lg', color: 'pink.0' }}
                {...form.getInputProps('sharedLogins')}
                value={sharedLoginsRadio}
                className='radio-group'
                onChange={(value) => {
                  form.setFieldValue('sharedLogins', value);
                  setSharedLoginsRadio(value);
                }}
                inputWrapperOrder={['label', 'input', 'error']}
                inputContainer={(children) => (
                  <Flex gap='xl' align='center' justify='center'>
                    {children}{' '}
                  </Flex>
                )}
              >
                <Group spacing='xl'>
                  <Radio value={'0'} label='Yes' />
                  <Radio value={'1'} label='No' />
                  <Radio value={'2'} label='Do not know' />
                </Group>
              </Radio.Group>
            </Portal>
          </Stepper.Step>

          <Stepper.Step
            label={largeScreen ? 'Question 7' : ''}
            description={largeScreen ? 'Antivirus' : ''}
            allowStepSelect={shouldAllowSelectStep(7)}
          >
            <Portal target='#step'>
              <Box mb={'lg'}>
                <Title order={largeScreen ? 1 : 3}>
                  Do you currently use antivirus on ALL devices?
                </Title>
              </Box>
              <Radio.Group
                size='xl'
                name='antivirusRadio'
                label={
                  <Text c='dimmed' fz='md' mb={'lg'}>
                    e.g. McAfee, Windows Defender, Webroot, Norton
                  </Text>
                }
                errorProps={{ mt: 'lg', color: 'pink.0' }}
                {...form.getInputProps('antivirus')}
                value={antivirusRadio}
                onChange={(value) => {
                  form.setFieldValue('antivirus', value);
                  setAntivirusRadio(value);
                }}
                inputWrapperOrder={['label', 'input', 'error']}
                inputContainer={(children) => (
                  <Flex gap='xl' align='center' justify='center'>
                    {children}{' '}
                  </Flex>
                )}
              >
                <Group spacing='xl'>
                  <Radio value={'1'} label='Yes' />
                  <Radio value={'0'} label='No' />
                  <Radio value={'2'} label='Do not know' />
                </Group>
              </Radio.Group>
            </Portal>
          </Stepper.Step>

          <Stepper.Step
            label={largeScreen ? 'Results' : ''}
            description={largeScreen ? 'Your Score' : ''}
          >
            <Box></Box>
          </Stepper.Step>
        </Stepper>
        <Portal target='#step-group'>
          <Group spacing={'xl'} mt='xl'>
            <Button
              variant='default'
              size={largeScreen ? 'xl' : 'md'}
              onClick={prevStep}
            >
              <FontAwesomeIcon icon={'chevron-left'} />
              {'\u00A0'}
              Back
            </Button>
            {active === 7 && (
              <Button
                type='submit'
                size={largeScreen ? 'xl' : 'md'}
                form='cyberform'
              >
                Results
                {'\u00A0'}
                <FontAwesomeIcon icon={'chevron-right'} />
              </Button>
            )}
            {active !== 7 && (
              <Button
                onClick={nextStep}
                size={largeScreen ? 'xl' : 'md'}
                color={'pink.0'}
              >
                Next
                {'\u00A0'}
                <FontAwesomeIcon icon={'chevron-right'} />
              </Button>
            )}
          </Group>
        </Portal>
      </Navbar>
    </form>
  );
}
export default NavbarStepper;
