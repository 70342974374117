import {
  Accordion,
  Button,
  Card,
  CardSection,
  Container,
  Flex,
  Text,
  Rating,
  Anchor,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Complete(props: any) {
  const [result, setResult] = useState(0);
  let navigate = useNavigate();
  useEffect(() => {
    let count = 0;
    console.log(props.values);
    Object.entries(props.values).map((value: any) => {
      if (value[1] === '1' && value[0] !== 'contactCheck') {
        count++;
      }
    });
    setResult(count);
  }, []);
  const largeScreen = useMediaQuery('(min-width: 450px)');
  library.add(faCheckCircle, faTimesCircle);

  return (
    <>
      <Container>
        <Card
          shadow='sm'
          style={{ marginLeft: '25%' }}
          p={largeScreen ? 'lg' : 'xs'}
          radius='md'
          withBorder
        >
          <CardSection pt='lg' ta={'center'} m='auto'>
            <Flex justify={'center'}>
              <Rating value={result} count={7} size='xl' readOnly></Rating>
            </Flex>
            <h1> You scored {result} / 7</h1>
            <h5>
              This score gives you an indication of how vulnerable you could be
              to various cyber threats. If you’d like to learn more about how we
              can provide a detailed assessment of your business’s current level
              of security, click the link below.
            </h5>
          </CardSection>

          <Accordion>
            <Accordion.Item value='mfa'>
              <Accordion.Control
                icon={
                  props.values.mfa === '1' ? (
                    <FontAwesomeIcon color='green' icon='check-circle' />
                  ) : (
                    <FontAwesomeIcon color='red' icon='times-circle' />
                  )
                }
              >
                <Text fw={700} fz={'md'}>
                  Do you have MFA (Multi Factor Authentication) enabled across
                  all platforms?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                MFA is important because it adds an extra layer of security to
                protect your accounts from unauthorised access. Typically, by
                requiring you to have another device to verify your identity
                with. E.g. authenticator app, calls or texts
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value='cyber'>
              <Accordion.Control
                icon={
                  props.values.cyberSecTraining === '1' ? (
                    <FontAwesomeIcon color='green' icon='check-circle' />
                  ) : (
                    <FontAwesomeIcon color='red' icon='times-circle' />
                  )
                }
              >
                <Text fw={700} fz={'md'}>
                  Do your staff undergo regular cyber security training?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                It’s important for employees to understand the importance of
                security measures and procedures, as well as how to recognise
                and respond to potential threats. With security awareness
                training, businesses can reduce their risk of being targeted by
                hackers, protect sensitive information, and ensure that their
                systems are secure.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value='password'>
              <Accordion.Control
                icon={
                  props.values.routerPassword === '1' ? (
                    <FontAwesomeIcon color='green' icon='check-circle' />
                  ) : (
                    <FontAwesomeIcon color='red' icon='times-circle' />
                  )
                }
              >
                <Text fw={700} fz={'md'}>
                  Do you change the default password on your internet router
                  when you first receive it?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Most hackers use your router to access your wireless home
                network as they almost know all default passwords of a router.
                Updating your default router password can help keep your home
                network safe and improve your device security.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value='data'>
              <Accordion.Control
                icon={
                  props.values.dataControl === '1' ? (
                    <FontAwesomeIcon color='green' icon='check-circle' />
                  ) : (
                    <FontAwesomeIcon color='red' icon='times-circle' />
                  )
                }
              >
                <Text fw={700} fz={'md'}>
                  Do you control what staff have access to certain data?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                There are various benefits of access control. For example, when
                a data breach occurs, the loss or the effect is minimal when an
                access control system is in place. The system also recognises
                the details of who accessed something restricted without
                authorisation. However, the absence of an access control policy
                exposes the company to internal and external cyber-attack.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value='admin'>
              <Accordion.Control
                icon={
                  props.values.adminPerms === '1' ? (
                    <FontAwesomeIcon color='green' icon='check-circle' />
                  ) : (
                    <FontAwesomeIcon color='red' icon='times-circle' />
                  )
                }
              >
                <Text fw={700} fz={'md'}>
                  Do your staff have administrator privileges to install
                  software on device?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Unmanaged administrator rights pose a high-security risk for any
                company. By simply removing the admin rights, you can block the
                entrance and limit the spread of many forms of malware. The
                overall impact of any external or internal threats is
                considerably reduced. Removing admin privileges from your
                organisation is the immediately effective, most powerful
                protective measure you can take.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value='logins'>
              <Accordion.Control
                icon={
                  props.values.sharedLogins === '1' ? (
                    <FontAwesomeIcon color='green' icon='check-circle' />
                  ) : (
                    <FontAwesomeIcon color='red' icon='times-circle' />
                  )
                }
              >
                <Text fw={700} fz={'md'}>
                  Do staff share PC logins?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Businesses often believe sharing login details, emails,
                passwords, and user accounts can save time and money. But these
                perceptions ignore essential information. When you understand
                the risk of account sharing, you see what you save in money; you
                risk losing in an attack. What you save in time, you risk
                doubling in human error. And that’s just the start.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value='antivirus'>
              <Accordion.Control
                icon={
                  props.values.antivirus === '1' ? (
                    <FontAwesomeIcon color='green' icon='check-circle' />
                  ) : (
                    <FontAwesomeIcon color='red' icon='times-circle' />
                  )
                }
              >
                <Text fw={700} fz={'md'}>
                  Do you currently use antivirus across ALL devices?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Antivirus is important to protect your devices from cyber
                attacks. Having an infected device will put your other devices
                at a much higher risk so it’s important to keep all devices
                protected.
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Flex pt='lg' justify={'center'}>
            {/* <Anchor
              href='https://www.bc-technologies.co.uk/articles/defending-against-cyber-threats-report'
              target='_blank'
            > */}
            <Button
              type='button'
              onClick={() => {
                navigate('/');
                if (window.confirm('Are you ready to finish?')) {
                  props.setReset(!props.reset);
                  navigate('/');
                }
              }}
            >
              Finish
            </Button>
            {/* </Anchor> */}
          </Flex>
        </Card>
        <div id='step'></div>
        <div id='complete'></div>
        <div id='step-group'></div>
      </Container>
    </>
  );
}
