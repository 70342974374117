export default function App() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'between',
        height: 'auto',
        marginLeft: '15%',
        overflow: 'visible',
      }}
    >
      <div id='step' className='contentPortal'></div>
      <div id='completed' className='contentPortal'></div>
      <div
        id='step-group'
        style={{ display: 'flex', justifyContent: 'center' }}
      ></div>
    </div>
  );
}
