import React, { useState } from 'react';
import './index.css';
import App from './App';
import {
  AppShell,
  Header,
  MantineProvider,
  Image,
  Box,
  Flex,
  Button,
  Title,
  Group,
} from '@mantine/core';
import NavbarStepper from './components/NavbarStepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faRecycle, faRobot } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mantine/hooks';

import { Route, Routes, useNavigate } from 'react-router-dom';
import Complete from './Complete';

library.add(faRecycle, faRobot);
export default function Main() {
  const [reset, setReset] = useState(false);
  const [formValues, setFormValues] = useState([]);
  let navigate = useNavigate();
  const largeScreen = useMediaQuery('(min-width: 450px)');
  return (
    <React.StrictMode>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          fontFamily: 'Poppins',
          components: {
            Portal: {
              styles: (theme, { variant }) => ({
                root: {
                  backgroundColor:
                    variant === 'filled'
                      ? theme.colors[theme.primaryColor][9]
                      : undefined,
                },
              }),
            },
          },

          colorScheme: 'light',
          colors: {
            purple: [
              '#6334E3',
              '#CFC0F6',
              '#B098F1',
              '#9170EB',
              '#7247E6',
              '#531FE0',
              '#4219B3',
              '#321386',
              '#210C5A',
              '#11062D',
            ],
            pink: [
              '#E8327C',
              '#FFE5F3',
              '#FFB8DF',
              '#FF8ACA',
              '#FF5CB5',
              '#FF2EA0',
              '#FF008B',
              '#CC006F',
              '#990054',
              '#660038',
            ],
            blue: [
              '#E7FBFD',
              '#BDF5FA',
              '#92EFF7',
              '#67E8F4',
              '#3DE2F0',
              '#12DBED',
              '#0EAFBE',
              '#0B838E',
              '#07585F',
              '#042C2F',
            ],
          },
          primaryColor: 'purple',
          shadows: {
            md: '1px 1px 3px rgba(0, 0, 0, .25)',
            xl: '5px 5px 3px rgba(0, 0, 0, .25)',
          },
        }}
      >
        <AppShell
          padding={largeScreen ? 'md' : 'xs'}
          navbar={
            <NavbarStepper
              key={reset}
              setValues={setFormValues}
            ></NavbarStepper>
          }
          header={
            <Header bg='purple.0' height={90} p='xs'>
              {/* Header content */}
              <Flex align='center' justify='space-between' direction='row'>
                <Image
                  height={70}
                  width='auto'
                  mx={0}
                  radius='md'
                  src='bctlogo.png'
                  alt='BC Technologies Logo'
                  fit='inherit'
                />
                <Group>
                  <FontAwesomeIcon color='#BDF5FA' icon='robot' size='2x' />
                  <Title order={1} c='white' fz={{ base: '20px', sm: '30px' }}>
                    Cyber Health Check
                  </Title>
                </Group>
                <Box></Box>
                {/* <Button
                  onClick={(event) => {
                    if (window.confirm('Are you ready to finish?')) {
                      setReset(!reset);
                      navigate('/');
                    }
                  }}
                >
                  <FontAwesomeIcon
                    id='resetIcon'
                    color='#CFC0F6'
                    icon='recycle'
                  />
                </Button> */}
              </Flex>
            </Header>
          }
          styles={(theme) => ({
            main: {
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
            },
          })}
        >
          <Routes>
            <Route path='/' element={<App></App>}></Route>
            <Route
              path='/complete'
              element={
                <Complete
                  values={formValues}
                  reset={reset}
                  setReset={setReset}
                />
              }
            ></Route>
          </Routes>
        </AppShell>
      </MantineProvider>
    </React.StrictMode>
  );
}
